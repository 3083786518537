import React from 'react'
import styled from 'styled-components'
import GenericPage from 'components/GenericPage'
import PreviewGallery from 'routes/reviews/PreviewGallery'
import PreviewInfo from 'routes/reviews/PreviewInfo'
import { MAX_CONTENT_WIDTH, SPACE } from 'Theme'
import Hero from 'routes/reviews/Hero'
import Content from 'routes/components/Content'
import SyllabusModal, {
  useSyllabusModal,
} from 'routes/components/SyllabusModal'
import SyllabusButton from 'routes/components/SyllabusButton'
import { DOCUMENT_URL, EMAIL, IS_COURSE_SOLD_OUT, VIDEO_URL } from 'constants/constants'
import { Box, Flex, Grid } from 'components/Layout'
import Reviews from 'routes/course/Reviews'
import studentZoltan from 'images/photos/zoltan-szalay.jpg'
import studentMarian from 'images/photos/marian-jendruch.jpg'
import studentMarika from 'images/photos/marika-sagiova.jpg'
import studentTara from 'images/photos/tara-stefanyi.jpg'
import studentMichal from 'images/photos/michal-cudrnak.jpg'
import studentPatrik from 'images/photos/patrik-prescak.jpg'
import { Text } from 'components/Typography'
import { GraphLegend, GraphList } from 'components/Results'
import CTASchedule from 'components/CTASchedule'
import FAQ from 'routes/components/FAQ'
import CheckList from 'components/CheckList'
import { UnderLineGatsbyLink } from 'components/UnderLineLink'
import PriceInfo from 'components/PriceInfo'
import TextLine from 'components/TextLine'
import ApplicationForm from 'routes/course/ApplicationForm'
import { rem } from 'polished'
import CoverPhoto from 'routes/course/CoverPhoto'
import productManagementCoverPhoto from 'images/photos/product-management-cover.jpg'
import ApplyButton from 'routes/components/ApplyButton'
import { DesktopOnly, MobileOnly } from 'components/ResponsiveDisplay'

const MENU_TITLES = {
  REVIEWS: 'Povedali o nás',
  RESULTS: 'Výsledky študentov',
  CONTENT: 'Ukážky obsahu kurzu',
  ADVANTAGES: 'Výhody kurzu',
  APPLICATION_FORM: 'Prihlasovací formulár',
  FAQ: 'FAQ',
}

const MENU_ORDER = [
  MENU_TITLES.REVIEWS,
  MENU_TITLES.RESULTS,
  MENU_TITLES.CONTENT,
  MENU_TITLES.ADVANTAGES,
  MENU_TITLES.APPLICATION_FORM,
  MENU_TITLES.FAQ,
]

const OfferWrapper = styled(Flex)`
  gap: ${SPACE.L};
`

const ReviewsPage = () => {
  const { isModalOpen, openModal, closeModal } = useSyllabusModal()

  return (
    <GenericPage maxContentWidth={MAX_CONTENT_WIDTH.COURSE}>
      <SyllabusModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        courseType="ProductManager"
        syllabusUrl={DOCUMENT_URL.SYLLABUS_PRODUCT_MANAGER}
      />

      <Hero videoUrl={VIDEO_URL.REVIEWS} />
      <Content
        menuContent={MENU_ORDER}
        menuButton={
          <ApplyButton
            id="apply-menu-productmanagement"
            mt={SPACE.M}
            isFullWidth
            sectionName={MENU_TITLES.APPLICATION_FORM}
          />
        }
      >
        <Content.Section isTitleHidden title={MENU_TITLES.REVIEWS}>
          <Reviews
            items={[
              {
                name: 'Zoltán Szalay',
                position: 'Project Manager',
                image: studentZoltan,
                text:
                  'Som veľmi rád, že som sa mohol zúčastniť tohto kurzu, pomohlo mi to lepšie pochopiť svet digitálnych produktov a verím, že budem vedieť aplikovať tieto poznatky aj vo svojej súčasnej praxi. Veľmi si vážim možnosť zúčastniť sa na interview s partnermi Sudo Academy.',
                isQuoted: true,
              },
              {
                name: 'Marián Jendruch',
                position: 'Web Designer',
                image: studentMarian,
                text:
                  'Kurz hodnotím veľmi pozitívne. Prišiel v správnom čase kedy mením kariérny smer a všetky vedomosti, zručnosti a kontakty z kurzu sa budú rozhodne hodiť. Reálne skúsenosti prídu s praxou, kurz mi však pomohol zorientovať sa v produktovom manažmente a fungovaní tech firiem.',
                isQuoted: true,
              },
              {
                name: 'Marika Šágiová',
                position: 'QA Test Enginner',
                image: studentMarika,
                text:
                  'Kurz bol výborným introm do PM problematiky, každá hodina bola zaujímavá a Janko je naozaj skvelý lektor. Páčili sa mi praktické cvičenia, stále nová téma a iný pohľad na vec. Pri case studies sme všetci spozorneli a snažili sa pochytiť čo najviac informácií.',
                isQuoted: true,
              },
              {
                name: 'Tara Stefányi',
                position: 'Software Developer & Scrum Master',
                image: studentTara,
                text:
                  'Kurz bol super, určite mi dal veľa informácií a znalostí. Páčilo sa mi množstvo príkladov z praxe, čo bolo jednoznačne veľké plus, zjednoduší to pochopenie a viem aký ma potom význam teória z lekcií. Aj možnosť zamestnať sa v zaujímavých firmách, takúto možnosť veľa kurzov neponúka.',
                isQuoted: true,
              },
              {
                name: 'Michal Čudrnák',
                position: 'Project Manager',
                image: studentMichal,
                text:
                  'Výborný kurz, odporúčam ho každému. Oceňujem rozdelenie tém do jednotlivých lekcií, praktické cvičenia, využitie rôznych nástrojov, príklady z praxe. Pokojne by som sa zúčastnil aj pokračovania tohto kurzu. Oceňujem aj možnosť pohovoru vo vybraných spoločnostiach.',
                isQuoted: true,
              },
              {
                name: 'Patrik Preščák',
                position: 'Startup Co-Founder',
                image: studentPatrik,
                text:
                  'Ďakujem za možnosť zúčastniť sa kurzu. Páčilo sa mi prepojenie na prax a reálne ukážky, ktoré mi pomôžu vo vlastných projektoch. Super výkon lektorov v každej lekcii. Je super že priamo po kurze sme mali možnosť nájsť si aj uplatnenie v mnohých produktových firmách.',
                isQuoted: true,
              },
            ]}
          />
        </Content.Section>
        <Content.Section title={MENU_TITLES.RESULTS}>
          <Text as="p" m={`0 0 ${SPACE.L}`} width="75%">
            Hodnotenie študentov ako zlepšili svoje vedomosti v kľúčových
            oblastiach Product Managementu pred a po absolvovaní kurzu
          </Text>
          <GraphLegend gray="Pred Kurzom" red="Po kurze" />
          <GraphList
            items={[
              {
                title: 'Product management general overview',
                base: 53,
                result: 88,
              },
              {
                title: 'User research & interviews',
                base: 39,
                result: 80,
              },
              {
                title: 'UI/UX, Product Design',
                base: 38,
                result: 78,
              },
              {
                title: 'Problem space vs. Solution space',
                base: 27,
                result: 77,
              },
              {
                title: 'MVP (Minimum Viable Product)',
                base: 39,
                result: 81,
              },
              {
                title: 'Product Roadmapping',
                base: 29,
                result: 73,
              },
              {
                title: "OKR's (Objectives & Key Results)",
                base: 27,
                result: 83,
              },
              {
                title: 'Agile Delivery Process',
                base: 42,
                result: 73,
              },
              {
                title: 'Product Analytics',
                base: 33,
                result: 74,
              },
            ]}
          />
        </Content.Section>
        <Content.Section
          title={MENU_TITLES.CONTENT}
          titleContent={
            <DesktopOnly>
              <SyllabusButton
                id="download-syllabus-productmanagement"
                hasDownloadIcon
                label="Získaj detailný sylabus kurzu"
                onClick={openModal}
              />
            </DesktopOnly>
          }
        >
          <PreviewGallery />
          <PreviewInfo />
          <Box mt={SPACE.L}>
            <MobileOnly>
              <SyllabusButton
                id="download-syllabus-productmanagement"
                hasDownloadIcon
                label="Získaj detailný sylabus kurzu"
                onClick={openModal}
                isFullWidth
              />
            </MobileOnly>
          </Box>
        </Content.Section>
        <Content.Section title={MENU_TITLES.ADVANTAGES}>
          <CheckList
            isGrid
            items={[
              {
                text: 'Priamy kontakt s lektormi',
              },
              {
                text: 'Sprostredkovanie práce a certifikát',
              },
              {
                text: 'Kvalitný a overený výukový materiál',
              },
              {
                text: 'Spoznanie sa s ľuďmi z tech. komunity',
              },
              {
                text: 'Praktické cvičenia a feedback od lektorov',
              },
              {
                text: 'Mentoring a individuálne konzultácie',
              },
            ]}
          />
        </Content.Section>
        <Content.Section title={MENU_TITLES.APPLICATION_FORM}>
          <OfferWrapper
            flexDirection={{ S: 'column', M: 'row' }}
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Grid
              flexDirection="column"
              gridGap={{ S: SPACE.M, M: SPACE.L }}
              width={`min(100%, ${rem(400)})`}
              mb={{ S: SPACE.L, M: 0 }}
            >
              <PriceInfo
                price="990€"
                description="Ideálna možnosť ak chceš zaplatiť celú sumu teraz a neodkladať to na neskôr."
                title="Plná cena"
              />
              <TextLine text="alebo" />
              <PriceInfo
                price="3 x 330€"
                description="Ak preferuješ rozdeliť platbu na 3 splátky, radi ti to umožníme."
                title="Splátky"
              />
            </Grid>
            <ApplicationForm
              coursePrice={990}
              courseType="ProductManager"
              isCourseSoldOut={IS_COURSE_SOLD_OUT}
            />
          </OfferWrapper>
        </Content.Section>
        <Content.Section title={MENU_TITLES.FAQ}>
          <FAQ
            items={[
              {
                question: 'Čo je úlohou Product Managera',
                answer: (
                  <>
                    Každý digitálny produkt potrebuje okrem programátorov a
                    dizajnérov dizajnérov aj produktového manažéra, ktorého
                    úlohou je detailne pochopiť potreby zákazníka a na ich
                    základe stanoviť produktovú víziu a stratégiu, koordinovať
                    následný vývoj a zodpovedať za biznisové výsledky produktu.
                    Aktuálne ide o jednu z najvyhľadávanejších pozícií v
                    technologických firmách. Prečítaj si náš detailný článok o
                    produktovom managemente{' '}
                    <UnderLineGatsbyLink to="/blog/preco-sa-stat-produktovym-manazerom">
                      TU
                    </UnderLineGatsbyLink>
                  </>
                ),
              },
              {
                question:
                  'Koľko hodín týždenne si potrebujem vyhradiť na tento kurz?',
                answer:
                  'Tento kurz je koncipovaný part-time formou, to znamená, že je možné ho bez problémov zvládnuť aj popri práci alebo štúdiu. Spoločná výuka bude prebiehať 1x do týždňa vo večerných hodinách od 18:00 do 21:00. Počas kurzu sa naviac ešte 2x uskutočnia spoločné pracovné worskopy (2 štvrtky večer od 18:00 do 20:00). Zvyšný čas behom týždňa je venovaný na vypracovanie domácich zadaní, samoštúdium a konzultácie s lektorom (max. 1 hodina do týždňa).',
              },
              {
                question:
                  'Získam automaticky po absolvovaní kurzu pracovnú ponuku?',
                answer:
                  'Po úspešnom absolvovaní kurzu ťa prepojíme s našimi IT partnermi a v prípade obojstranného záujmu ti sprostredkujeme u ktoréhokoľvek z nich pracovný pohovor. To, či tento pohovor skončí úspešne je už samozrejme na tebe, ale môžeme ti garantovať, že po vedomostnej stránke budeš ovládať všetko čo je potrebné na získanie práce produktového manažéra.',
              },
              {
                question:
                  'Aktuálne nemám žiadne skúsenosti s IT, môžem sa aj tak prihlásiť na tento kurz?',
                answer:
                  'Ak ťa zaujímajú technológie a máš nápad na digitálny produkt, ktorý by si rád validoval a naučil sa ako pristupovať k jeho realizácii, tak potom bude tento kurz pre teba určite vhodný. Zašli nám svoju online prihlášku, ktorá je nezáväzná a na vstupnom online pohovore, si spolu prejdeme všetky tvoje prípadné otázky a potvrdíme vhodnosť tvojho zapojenia.',
              },
              {
                question:
                  'Zaujímam sa o pozíciu “Product owner”, bude pre mňa tento kurz prínosný?',
                answer:
                  'Určite áno! Product owner je pôvodne názov role v rámci metodológie SCRUM a z veľkej väčšiny sa kompetencie Product Ownera a Product Manažéra prekrývajú (v mnohých firmách ide iba o rozdielnu terminológiu). Tento kurz plnohodnotne pokrýva všetky aspekty práce Product Ownera (od prioritizácie roadmapy, cez jej delivery až po výslednú analytiku) a pridáva naviac aj strategický pohľad prvotnú definíciu toho, akú hodnotu má produkt prinášať pre užívateľa.',
              },
            ]}
          />
          <CTASchedule
            description="Ak máš akékoľvek otázky ohľadom nášho kurzu, naplánuj si s nami nezáväznú konzultáciu a v krátkom video telefonáte si spolu prejdeme všetko čo ťa zaujíma."
            buttonText="Vyber si termín konzultácie"
            subButtonText="alebo nám napíš na "
            subButtonEmail={EMAIL.SUDO_ACADEMY}
          />
        </Content.Section>
      </Content>
      <CoverPhoto src={productManagementCoverPhoto} />
    </GenericPage>
  )
}

export default ReviewsPage
